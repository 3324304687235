import { Link as RouterLink } from 'react-router-dom'
import { Link, Container, Typography, Stack, Box } from '@mui/material'
import { styled } from '@mui/material/styles'

import { LoginForm } from '../../sections/auth'

export default function Login() {
  return (
    <Container maxWidth='sm'>
      <ContentStyle>
        <Stack direction='row' justifyContent='space-between' alignItems='center' mb={3}>
          <Typography variant='h4'>Вход</Typography>
          <Link component={RouterLink} to='/auth/register'>
            Регистрация
          </Link>
        </Stack>

        <LoginForm />

        <Box textAlign='right'>
          <Link component={RouterLink} to='/auth/forgot-password'>
            Забыли пароль?
          </Link>
        </Box>
      </ContentStyle>
    </Container>
  )
}

const ContentStyle = styled('div')(({ theme }) => ({
  maxWidth: 400,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}))
