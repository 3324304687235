import { IExamStatus, IPaymentStatus, IUserStatus } from '@kansonar-exam-front/graphql'

export const PAYMENT_AMOUNT = 17000
export const DATE_FORMAT = 'DD.MM.YYYY'
export const DATE_TIME_FORMAT = `${DATE_FORMAT} HH:mm`

export const PHONE_REGEXP =
  /^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$/

interface IMapItem<T> {
  value: T
  label: string
  color: string
}

export interface IPayment {
  id: number
  status: number
  createdAt: Date
}

export const USER_STATUS_MAP: IMapItem<IUserStatus>[] = [
  { value: IUserStatus.NEW, label: 'Новый', color: 'info.main' },
  { value: IUserStatus.PENDING, label: 'На рассмотрении', color: 'warning.main' },
  { value: IUserStatus.ACTIVE, label: 'Активный', color: 'success.main' },
  { value: IUserStatus.INACTIVE, label: 'Неактивный', color: 'text.secondary' },
]

export const EXAM_STATUS_MAP: IMapItem<IExamStatus>[] = [
  { value: IExamStatus.IN_PROGRESS, label: 'В процессе', color: 'info.main' },
  { value: IExamStatus.NEW, label: 'На рассмотрении', color: 'warning.main' },
  { value: IExamStatus.SUCCESS, label: 'Сдан', color: 'success.main' },
  { value: IExamStatus.PENDING, label: 'В ожидании', color: 'warning.main' },
  { value: IExamStatus.FAIL, label: ' Не сдан', color: 'error.main' },
  { value: IExamStatus.CANCEL, label: ' Отменен', color: 'text.secondary' },
]

export const PAYMENT_STATUS_MAP: IMapItem<IPaymentStatus>[] = [
  { value: IPaymentStatus.NEW, label: 'Новый', color: 'info.main' },
  { value: IPaymentStatus.PENDING, label: 'В ожидании', color: 'warning.main' },
  { value: IPaymentStatus.PAID, label: 'Активный', color: 'success.main' },
  { value: IPaymentStatus.FAILED, label: 'Отклонен', color: 'error.main' },
]
