import { useRef, useState } from 'react'
import { alpha } from '@mui/material/styles'
import { Box, MenuItem, Stack, IconButton, Typography } from '@mui/material'
import { MenuPopover } from '@kansonar-exam-front/ui-kit'
import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

const LANGS = [
  {
    value: 'ru',
    label: 'Русский',
    icon: 'https://raw.githubusercontent.com/hampusborgos/country-flags/main/png100px/ru.png',
  },
  {
    value: 'kk',
    label: 'Казакша',
    icon: 'https://raw.githubusercontent.com/hampusborgos/country-flags/main/png100px/kz.png',
  },
]

export function LanguagePopover() {
  const [query, setQuery] = useSearchParams()
  const { i18n } = useTranslation()
  const anchorRef = useRef(null)
  const [open, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  const handleChange = (lng: string) => {
    i18n.changeLanguage(lng)

    // Reset pagination
    if (query.get('page')) {
      query.delete('page')
      setQuery(query)
    }

    handleClose()
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          padding: 0,
          width: 44,
          height: 44,
          ...(open && {
            bgcolor: (theme) =>
              alpha(theme.palette.primary.main, theme.palette.action.focusOpacity),
          }),
        }}
      >
        <Typography variant='subtitle1' color='grey.500'>
          {i18n.language.toUpperCase()}
        </Typography>
      </IconButton>

      <MenuPopover
        open={open}
        onClose={handleClose}
        anchorEl={anchorRef.current}
        sx={{
          mt: 1.5,
          ml: 0.75,
          width: 180,
          '& .MuiMenuItem-root': { px: 1, typography: 'body2', borderRadius: 0.75 },
        }}
      >
        <Stack spacing={0.75}>
          {LANGS.map((option) => (
            <MenuItem
              key={option.value}
              selected={option.value === i18n.language}
              onClick={() => handleChange(option.value)}
            >
              <Box component='img' alt={option.label} src={option.icon} sx={{ width: 28, mr: 2 }} />

              {option.label}
            </MenuItem>
          ))}
        </Stack>
      </MenuPopover>
    </>
  )
}
