import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import { I18nLocaleDetector } from './i18nLocaleDetector'

export const LANGUAGES = ['ru', 'kk']

i18next
  .use(I18nLocaleDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'ru',
    supportedLngs: LANGUAGES,
    defaultNS: 'root',
    resources: {
      ru: { root: require('./locales/ru.json') },
      kk: { root: require('./locales/kk.json') },
    },
  })

export default i18next
