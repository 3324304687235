import { useForgotPasswordMutation } from '@kansonar-exam-front/graphql'
import { Stack, TextField } from '@mui/material'
import * as Yup from 'yup'
import { Form, FormikProvider, useFormik } from 'formik'
import { useIsMountedRef } from '@kansonar-exam-front/ui-kit'
import { LoadingButton } from '@mui/lab'

interface IFormValues {
  email: string
  afterSubmit?: string
}

type Props = {
  onSent: VoidFunction
  onGetEmail: (value: string) => void
}

export function ForgotPasswordForm({ onSent, onGetEmail }: Props) {
  const isMountedRef = useIsMountedRef()
  const [forgotPassword] = useForgotPasswordMutation()

  const forgotPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email('Email должен быть действительным адресом электронной почты')
      .required('Email обязателен'),
  })

  const formik = useFormik<IFormValues>({
    initialValues: {
      email: '',
    },
    validationSchema: forgotPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const { data } = await forgotPassword({
          variables: {
            email: values.email,
          },
        })

        if (data?.forgotPassword) {
          onGetEmail(values.email)
          onSent()
          setSubmitting(false)
        }
      } catch (error) {
        console.error(error)
        setErrors({ afterSubmit: error.message })
      } finally {
        if (isMountedRef.current) {
          setSubmitting(false)
        }
      }
    },
  })

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} mb={3}>
          <TextField
            label='Email'
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />

          <LoadingButton type='submit' size='large' variant='contained' loading={isSubmitting}>
            Отправить
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  )
}
