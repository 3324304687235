import { ElementType, lazy, Suspense } from 'react'
import { Routes, Route, Navigate } from 'react-router-dom'
import { Box, CircularProgress } from '@mui/material'

import { AuthLayout } from './layout/AuthLayout'
import DashboardLayout from './layout/dashboard'
import { AuthGuard, GuestGuard } from './guards'

function Router() {
  return (
    <Routes>
      <Route
        path='auth'
        element={
          <GuestGuard>
            <AuthLayout />
          </GuestGuard>
        }
      >
        <Route index element={<Navigate to='/auth/login' replace />} />
        <Route path='login' element={<Login />} />
        <Route path='register' element={<Register />} />
        <Route path='forgot-password' element={<ForgotPassword />} />
        <Route path='reset-password/:token' element={<ResetPassword />} />
        <Route path='confirmation/:token' element={<EmailConfirmation />} />
      </Route>

      <Route
        path='/'
        element={
          <AuthGuard>
            <DashboardLayout />
          </AuthGuard>
        }
      >
        <Route index element={<Navigate to='/dashboard' replace />} />
        <Route path='dashboard' element={<Dashboard />} />

        <Route path='lessons' element={<Lessons />} />
        <Route path='lessons/:id' element={<Lesson />} />

        <Route path='/exam/:id' element={<Exam />} />

        <Route path='profile'>
          <Route index element={<Navigate to='/profile/details' replace />} />
          <Route path='details' element={<ProfileDetails />} />
          <Route path='edit' element={<UpdateProfile />} />
          <Route path='edit/user' element={<UpdateUser />} />
        </Route>
      </Route>

      <Route path='*'>
        <Route path='404' element={<NotFound />} />
        <Route path='*' element={<Navigate to='/404' replace />} />
      </Route>
    </Routes>
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense
      fallback={
        <Box p={2} textAlign='center'>
          <CircularProgress />
        </Box>
      }
    >
      <Component {...props} />
    </Suspense>
  )

const NotFound = Loadable(lazy(() => import('./pages/Page404')))
const Login = Loadable(lazy(() => import('./pages/auth/Login')))
const Register = Loadable(lazy(() => import('./pages/auth/Register')))
const ForgotPassword = Loadable(lazy(() => import('./pages/auth/ForgotPassword')))
const ResetPassword = Loadable(lazy(() => import('./pages/auth/ResetPassword')))
const EmailConfirmation = Loadable(lazy(() => import('./pages/auth/EmailConfirmation')))
const UpdateUser = Loadable(lazy(() => import('./pages/profile/UpdateUser')))
const Dashboard = Loadable(lazy(() => import('./pages/dashboard/Dashboard')))
const UpdateProfile = Loadable(lazy(() => import('./pages/profile/UpdateProfile')))
const ProfileDetails = Loadable(lazy(() => import('./pages/profile/ProfileDetails')))
const Lessons = Loadable(lazy(() => import('./pages/lessons/Lessons')))
const Lesson = Loadable(lazy(() => import('./pages/lessons/Lesson')))
const Exam = Loadable(lazy(() => import('./pages/exams/Exam')))

export default Router
