import React, { useRef, useState } from 'react'
import { Link as RouterLink, useNavigate } from 'react-router-dom'
import { alpha } from '@mui/material/styles'
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton } from '@mui/material'
import { MenuPopover, useIsMountedRef } from '@kansonar-exam-front/ui-kit'

import { useStores } from '../../stores/hooks'

const MENU_OPTIONS = [
  {
    label: 'Главная',
    icon: 'eva:home-fill',
    linkTo: '/',
  },
  {
    label: 'Профиль',
    icon: 'eva:person-fill',
    linkTo: '/profile',
  },
]

export function AccountPopover() {
  const { authStore } = useStores()
  const { user } = authStore
  const navigate = useNavigate()
  const isMountedRef = useIsMountedRef()
  const anchorRef = useRef(null)

  const [open, setOpen] = useState<HTMLButtonElement | null>(null)

  const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setOpen(event.currentTarget)
  }

  const handleClose = () => {
    setOpen(null)
  }

  const handleLogout = () => {
    try {
      if (isMountedRef.current) {
        navigate('/')
      }
      authStore.logout()
    } catch (error) {
      console.error(error)
    }
  }

  return (
    <>
      <IconButton
        ref={anchorRef}
        onClick={handleOpen}
        sx={{
          p: 0,
          ...{
            '&:before': open && {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          },
        }}
      >
        <Avatar alt='photoURL'>{'AS'}</Avatar>
      </IconButton>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant='subtitle2' noWrap>
            {user?.fullName}
          </Typography>
          <Typography variant='body2' sx={{ color: 'text.secondary' }} noWrap>
            {user?.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem
              key={option.label}
              to={option.linkTo}
              component={RouterLink}
              onClick={handleClose}
            >
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Выйти
        </MenuItem>
      </MenuPopover>
    </>
  )
}
