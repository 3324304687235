// https://github.com/apollographql/apollo-feature-requests/issues/6#issuecomment-465305186
export const stripTypenames = (value: any, key: string): any => {
  if (Array.isArray(value)) {
    return value.map((i) => stripTypenames(i, key))
  } else if (typeof value === 'object' && value !== null) {
    return Object.keys(value).reduce((newObject, k) => {
      if (k === key) return newObject
      return Object.assign({ [k]: stripTypenames(value[k], key) }, newObject)
    }, {})
  }

  return value
}
