import { ApolloClient, ApolloLink, InMemoryCache } from '@apollo/client'
import { onError, ErrorResponse } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'

import { stripTypenames } from './helpers'

export interface ICreateClientOptions {
  apiUrl: string
  getToken?: () => string
  onError?: (error: ErrorResponse) => void
}

export function createApolloClient(options: ICreateClientOptions) {
  const httpLink: ApolloLink = createUploadLink({
    uri: options.apiUrl,
  })

  const getAuthHeader = () => {
    const token = options.getToken?.()

    return {
      authorization: token ? `Bearer ${token}` : '',
    }
  }

  const authMiddlewareLink = new ApolloLink((operation, forward) => {
    operation.setContext({
      headers: getAuthHeader(),
    })

    return forward(operation)
  })

  const removeTypenameMiddleware = new ApolloLink((operation, forward) => {
    if (operation.variables) {
      const variables = Object.keys(operation.variables)

      if (!variables.includes('file')) {
        operation.variables = stripTypenames(operation.variables, '__typename')
      }
    }

    return forward(operation)
  })

  const links = [authMiddlewareLink, removeTypenameMiddleware, httpLink]

  if (options.onError) {
    links.unshift(onError(options.onError))
  }

  return new ApolloClient({
    link: ApolloLink.from(links),
    cache: new InMemoryCache(),
  })
}
