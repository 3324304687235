import { LanguageDetectorModule } from 'i18next'
import moment from 'moment'

export const I18nLocaleDetector: LanguageDetectorModule = {
  type: 'languageDetector',
  init() {},
  detect() {
    const lang = window.localStorage.getItem('_lang') || 'ru'

    moment.locale(lang)

    return lang
  },
  cacheUserLanguage(lng) {
    window.localStorage.setItem('_lang', lng)
  },
}
