import { useResetPasswordMutation } from '@kansonar-exam-front/graphql'
import { LoadingButton } from '@mui/lab'
import { Stack, TextField, Alert } from '@mui/material'
import { Form, FormikProvider, useFormik } from 'formik'
import { useNavigate } from 'react-router-dom'
import * as Yup from 'yup'
import { useIsMountedRef } from '@kansonar-exam-front/ui-kit'

interface IFormValues {
  newPassword: string
  newPasswordConfirmation: string
  afterSubmit?: string
}

type Props = {
  token: string
}

export function ResetPasswordForm({ token }: Props) {
  const isMountedRef = useIsMountedRef()
  const [resetPassword] = useResetPasswordMutation()
  const navigate = useNavigate()

  const resetPasswordSchema = Yup.object().shape({
    newPassword: Yup.string().required('Пароль обязателен'),
    newPasswordConfirmation: Yup.string()
      .oneOf([Yup.ref('newPassword'), null], 'Пароли должны совпадать')
      .required('Введите подтверждение пароля'),
  })

  const formik = useFormik<IFormValues>({
    initialValues: {
      newPassword: '',
      newPasswordConfirmation: '',
    },
    validationSchema: resetPasswordSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const { data } = await resetPassword({
          variables: {
            token,
            newPassword: values.newPassword,
          },
        })
        if (data?.resetPassword) {
          setSubmitting(false)
          navigate('/auth/login')
        }
      } catch (error) {
        console.error(error)
        setErrors({ afterSubmit: error.message })
      } finally {
        if (isMountedRef.current) {
          setSubmitting(false)
        }
      }
    },
  })

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} mb={3}>
          {!!errors.afterSubmit && <Alert severity='error'>{errors.afterSubmit}</Alert>}

          <TextField
            fullWidth
            label='Новый пароль'
            type='password'
            {...getFieldProps('newPassword')}
            error={Boolean(touched.newPassword && errors.newPassword)}
            helperText={touched.newPassword && errors.newPassword}
          />
          <TextField
            fullWidth
            label='Подтверждение пароля'
            type='password'
            {...getFieldProps('newPasswordConfirmation')}
            error={Boolean(touched.newPasswordConfirmation && errors.newPasswordConfirmation)}
            helperText={touched.newPasswordConfirmation && errors.newPasswordConfirmation}
          />

          <LoadingButton type='submit' size='large' variant='contained' loading={isSubmitting}>
            Отправить
          </LoadingButton>
        </Stack>
      </Form>
    </FormikProvider>
  )
}
