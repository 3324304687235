import { ReactNode } from 'react'
import { LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment'
import 'moment/locale/ru'

import { NotistackProvider, ScrollToTop } from './components'
import { ConfirmDialogProvider } from './contexts'
import ThemeProvider from './theme'
import GlobalStyles from './theme/globalStyles'

type Props = {
  children: ReactNode
}

function UIKitProvider({ children }: Props) {
  return (
    <ThemeProvider>
      <LocalizationProvider dateAdapter={AdapterMoment}>
        <NotistackProvider>
          <ConfirmDialogProvider>
            <GlobalStyles />
            <ScrollToTop />
            {children}
          </ConfirmDialogProvider>
        </NotistackProvider>
      </LocalizationProvider>
    </ThemeProvider>
  )
}

export default UIKitProvider
