import { useContext } from 'react'

import { ConfirmDialogContext, IConfirmDialogProps } from '../contexts/ConfirmDialogContext'

export function useConfirmDialog() {
  const confirm = useContext(ConfirmDialogContext)

  return confirm === null
    ? (options: IConfirmDialogProps) => new Promise<void>((resolve, reject) => resolve())
    : confirm
}
