import { useDropzone, DropzoneOptions, FileRejection } from 'react-dropzone'
import { alpha, styled } from '@mui/material/styles'
import { Box, Theme, Typography, Paper, SxProps, Stack } from '@mui/material'

import { Image } from './Image'
import { Iconify } from './Iconify'

const DropZoneStyle = styled('div')(({ theme }) => ({
  outline: 'none',
  display: 'flex',
  overflow: 'hidden',
  textAlign: 'center',
  position: 'relative',
  alignItems: 'center',
  flexDirection: 'column',
  justifyContent: 'center',
  padding: theme.spacing(2, 0),
  borderRadius: theme.shape.borderRadius,
  transition: theme.transitions.create('padding'),
  backgroundColor: alpha(theme.palette.background.paper, 0.5),
  border: `2px dashed ${theme.palette.grey[300]}`,
  '&:hover': {
    opacity: 0.72,
    cursor: 'pointer',
  },
  [theme.breakpoints.up('md')]: { textAlign: 'left', flexDirection: 'row' },
}))

// ----------------------------------------------------------------------

interface CustomFile extends File {
  path?: string
  preview?: string
}

interface IProps extends DropzoneOptions {
  error?: boolean
  file?: CustomFile | string | null
  sx?: SxProps<Theme>
}

export function UploadSingleFile({ error = false, file, sx, ...other }: IProps) {
  const { getRootProps, getInputProps, isDragActive, isDragReject, fileRejections } = useDropzone({
    multiple: false,
    ...other,
  })

  return (
    <Box sx={{ width: '100%', ...sx }}>
      <DropZoneStyle
        {...getRootProps()}
        sx={{
          ...(isDragActive && { opacity: 0.72 }),
          ...((isDragReject || error) && {
            color: 'error.main',
            borderColor: 'error.light',
            bgcolor: 'error.lighter',
          }),
          ...(file && { padding: '12% 0' }),
        }}
      >
        <input {...getInputProps()} />

        <Stack direction='row' p={2} spacing={1} alignItems='center'>
          <Iconify icon='eva:cloud-upload-outline' fontSize={48} color='info.light' />
          <Typography variant='body2' color='grey.500'>
            Перетащите файл сюда или нажмите чтобы загрузить
          </Typography>
        </Stack>
        {file && (
          <Image
            sx={{
              top: 8,
              borderRadius: 1,
              objectFit: 'cover',
              position: 'absolute',
              width: 'calc(100% - 54px)',
              height: 'calc(100% - 54px)',
            }}
          />
        )}
      </DropZoneStyle>

      {fileRejections.length > 0 && <ShowRejectionItems items={fileRejections} />}
    </Box>
  )
}

function ShowRejectionItems({ items }: { items: FileRejection[] }) {
  return (
    <Paper
      variant='outlined'
      sx={{
        py: 1,
        px: 2,
        mt: 3,
        borderColor: 'error.light',
        bgcolor: (theme) => alpha(theme.palette.error.main, 0.08),
      }}
    >
      {items.map(({ file, errors }) => {
        const { path }: CustomFile = file

        return (
          <Box key={path} sx={{ my: 1 }}>
            {errors.map((e) => (
              <Typography key={e.code} variant='caption' component='p'>
                - {e.message}
              </Typography>
            ))}
          </Box>
        )
      })}
    </Paper>
  )
}
