import React, { useState, useCallback } from 'react'
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogProps,
  ButtonProps,
  Box,
  DialogTitle,
} from '@mui/material'

type ConfirmDialogContextProps = (confirmProps: IConfirmDialogProps) => Promise<any>

const ConfirmDialogContext = React.createContext<ConfirmDialogContextProps | null>(null)

export interface IConfirmDialogProps {
  message?: string
  labelOk?: string
  labelCancel?: string
  dialogProps?: DialogProps
  confirmButtonProps?: ButtonProps
  cancelButtonProps?: ButtonProps
}

interface IResolveReject {
  resolve?: ((value?: unknown) => void) | undefined
  reject?: ((reason?: unknown) => void) | undefined
}

const ConfirmDialogProvider: React.FC<React.PropsWithChildren<IConfirmDialogProps>> = ({
  children,
  message = '',
  labelOk = 'Да',
  labelCancel = 'Нет',
  dialogProps,
  confirmButtonProps = {},
  cancelButtonProps = {},
}) => {
  const defaultOptions: IConfirmDialogProps = {
    message,
    labelOk,
    labelCancel,
    dialogProps,
    confirmButtonProps,
    cancelButtonProps,
  }
  const [options, setOptions] = useState<IConfirmDialogProps>(defaultOptions)
  const [resolveReject, setResolveReject] = useState<IResolveReject>({})
  const { resolve, reject } = resolveReject

  const confirm = useCallback(
    (confirmOptions = {}) =>
      new Promise((res, rej) => {
        setOptions({ ...defaultOptions, ...confirmOptions })
        setResolveReject({ resolve: res, reject: rej })
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const handleClose = useCallback(() => {
    setResolveReject({})
  }, [])

  const handleCancel = useCallback(() => {
    reject && reject()
    handleClose()
  }, [reject, handleClose])

  const handleConfirm = useCallback(() => {
    resolve && resolve()
    handleClose()
  }, [resolve, handleClose])

  const isOpen = !!resolveReject.reject && !!resolveReject.resolve

  return (
    <>
      <ConfirmDialogContext.Provider value={confirm}>{children}</ConfirmDialogContext.Provider>
      <Dialog open={isOpen} fullWidth maxWidth='xs' {...options.dialogProps}>
        <DialogTitle>Подтвердите действие</DialogTitle>
        <DialogContent>
          <Box minWidth={200} mt={2}>
            {options.message}
          </Box>
        </DialogContent>
        <DialogActions>
          <Button
            color='primary'
            variant={'contained'}
            onClick={handleCancel}
            {...options.cancelButtonProps}
          >
            {options.labelCancel}
          </Button>
          <Button
            onClick={handleConfirm}
            color='primary'
            variant={'contained'}
            {...options.confirmButtonProps}
          >
            {options.labelOk}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export { ConfirmDialogProvider, ConfirmDialogContext }
