import { InputAdornment } from '@mui/material'
import TextField from '@mui/material/TextField'
import { MobileDatePicker } from '@mui/x-date-pickers'

import { Iconify } from '../Iconify'

interface IProps {
  label?: string
  value: Date | null
  onChange: (value: Date | null) => void
  error?: boolean
}

export function DatePicker({ label, value, onChange, error }: IProps) {
  return (
    <MobileDatePicker
      label={label}
      value={value}
      onChange={onChange}
      openTo='year'
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          error={error}
          InputProps={{
            endAdornment: (
              <InputAdornment position='end'>
                <Iconify icon='fa:calendar' width={20} height={20} />
              </InputAdornment>
            ),
          }}
        />
      )}
    />
  )
}
