import React from 'react'
import { TextField, TextFieldProps } from '@mui/material'
import InputMask from 'react-input-mask'

export const PhoneField: React.FC<TextFieldProps> = ({ value, onChange, onBlur, ...props }) => {
  const maskValue = typeof value === 'string' ? value : ''

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.target.value = e.target.value.length > 1 ? `+${e.target.value.replace(/\D+/g, '')}` : ''
    onChange?.(e)
  }

  return (
    <InputMask
      mask={'+7 (999) 999-99-99'}
      value={maskValue}
      onChange={handleChange}
      onBlur={onBlur}
    >
      <TextField {...props} type='tel' />
    </InputMask>
  )
}
