import { Box, Button, Dialog, DialogContent, Stack, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

interface IProps {
  open: boolean
  onClose: VoidFunction
}

export const ContactsDialog: React.FC<IProps> = ({ open, onClose }) => {
  return (
    <Dialog open={open} maxWidth='sm' fullWidth onClose={onClose}>
      <DialogContent>
        <Stack direction='row' spacing={1} alignItems='center' mb={3}>
          <img src='/logo192.png' alt='Kansonar' width={34} />
          <Typography
            variant='h5'
            color='primary.main'
            component={Link}
            to='/'
            sx={{ textDecoration: 'none', ml: 1 }}
          >
            KANSONAR
          </Typography>
        </Stack>
        <Typography mb={3}>
          Представительство Республиканской Ассоциации общественных объединений охотников и
          субъектов охотничьего хозяйства «Кансонар» по г. Алматы и Алматинской области
        </Typography>
        <Stack mb={3}>
          <Typography variant='body2' color='text.secondary'>
            Адрес: РК г. Алматы ул.Манаса 22Б
          </Typography>
          <Typography variant='body2' color='text.secondary'>
            Тел: +7 (727) 379-92-04, +7 (727) 379-91-84
          </Typography>
        </Stack>
        <Box textAlign='right'>
          <Button onClick={onClose}>Закрыть</Button>
        </Box>
      </DialogContent>
    </Dialog>
  )
}
