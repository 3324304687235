import { observer } from 'mobx-react-lite'
import { IUser, useMeLazyQuery } from '@kansonar-exam-front/graphql'
import { useEffect } from 'react'
import { Box, CircularProgress } from '@mui/material'
import { styled } from '@mui/material/styles'

import { useStores } from '../stores/hooks'

const RootStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  position: 'fixed',
  zIndex: theme.zIndex.modal + 100,
  width: '100%',
  minHeight: '100%',
  alignItems: 'center',
  justifyContent: 'center',
  overflow: 'hidden',
  backgroundColor: theme.palette.background.default,
}))

export const WatchUser = observer(() => {
  const { authStore } = useStores()
  const [fetchMe] = useMeLazyQuery({
    fetchPolicy: 'network-only',
  })

  async function fetchUser() {
    try {
      authStore.setLoading(true)

      const { data } = await fetchMe()

      if (data?.me) {
        authStore.setUser(data.me as IUser)
      }

      authStore.setLoading(false)
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(
    () => {
      if (authStore.token) {
        fetchUser()
      } else {
        authStore.setLoading(false)
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [authStore.token]
  )

  if (authStore.loading) {
    return (
      <RootStyle>
        <CircularProgress size={40} />
      </RootStyle>
    )
  }

  return null
})
