import { useState } from 'react'
import { Link } from 'react-router-dom'
import { alpha, styled } from '@mui/material/styles'
import { Box, Stack, AppBar, Toolbar, IconButton, Typography } from '@mui/material'
import { Iconify } from '@kansonar-exam-front/ui-kit'

import { LanguagePopover } from './LanguagePopover'
import { AccountPopover } from './AccountPopover'

import { ContactsDialog } from '../../sections/dashboard/ContactsDialog'

const APPBAR_MOBILE = 64
const APPBAR_DESKTOP = 92

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',

  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.paper, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `100%`,
  },
}))

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
}))

// ----------------------------------------------------------------------

export function DashboardNavbar() {
  const [openModal, setOpenModal] = useState(false)

  const toggleModal = () => {
    setOpenModal((prev) => !prev)
  }

  return (
    <RootStyle>
      <ToolbarStyle>
        <img src='/logo192.png' alt='Kansonar' width={34} />
        <Typography
          variant='h5'
          color='primary.main'
          component={Link}
          to='/'
          sx={{ textDecoration: 'none', ml: 1 }}
        >
          KANSONAR
        </Typography>
        <IconButton sx={{ mr: 1, color: 'grey.400' }} onClick={() => toggleModal()}>
          <Iconify icon='eva:info-outline' />
        </IconButton>
        <Box sx={{ flexGrow: 1 }} />
        <Stack direction='row' alignItems='center' spacing={{ xs: 0.5, sm: 1.5 }}>
          <LanguagePopover />
          <AccountPopover />
        </Stack>
      </ToolbarStyle>
      {openModal && <ContactsDialog open onClose={() => toggleModal()} />}
    </RootStyle>
  )
}
