import { useState } from 'react'
import { Checkbox, FormControlLabel, Link, Stack, TextField, Typography } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import * as Yup from 'yup'
import { PhoneField, useIsMountedRef } from '@kansonar-exam-front/ui-kit'
import { useRegisterMutation } from '@kansonar-exam-front/graphql'
import { Form, FormikProvider, useFormik } from 'formik'

import { PHONE_REGEXP } from '../../core/constants'

interface IFormValues {
  email: string
  firstName: string
  lastName: string
  middleName?: string
  password: string
  passwordConfirmation: string
  phone: string
  afterSubmit?: string
}

interface IProps {
  onSent: VoidFunction
  onGetEmail: (value: string) => void
}

export function RegisterForm({ onSent, onGetEmail }: IProps) {
  const isMountedRef = useIsMountedRef()
  const [registerUser] = useRegisterMutation()
  const [checked, setChecked] = useState(false)

  const LoginSchema = Yup.object().shape({
    firstName: Yup.string().required('Имя обязательное'),
    lastName: Yup.string().required('Фамилия обязательное'),
    email: Yup.string()
      .email('Email должен быть действительным адресом электронной почты')
      .required('Email обязателен'),
    password: Yup.string().required('Пароль обязателен'),
    passwordConfirmation: Yup.string()
      .oneOf([Yup.ref('password'), null], 'Пароли должны совпадать')
      .required('Введите подтверждение пароля'),
    phone: Yup.string()
      .trim()
      .matches(PHONE_REGEXP, 'Не правильный формат телефона')
      .required('Телефон обязателен'),
  })

  const formik = useFormik<IFormValues>({
    initialValues: {
      firstName: '',
      lastName: '',
      middleName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      phone: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting, resetForm }) => {
      try {
        const { data } = await registerUser({
          variables: {
            input: {
              firstName: values.firstName,
              lastName: values.lastName,
              middleName: values.middleName,
              email: values.email,
              password: values.password,
              phone: values.phone,
            },
          },
        })

        if (data?.register) {
          onGetEmail(values.email)
          onSent()
        }
      } catch (error) {
        console.error(error)
        setErrors({ afterSubmit: error.message })
      } finally {
        if (isMountedRef.current) {
          setSubmitting(false)
        }
      }
    },
  })

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Stack spacing={3} mb={3}>
          <TextField
            fullWidth
            autoComplete='username'
            label='Фамилия'
            {...getFieldProps('lastName')}
            error={Boolean(touched.lastName && errors.lastName)}
            helperText={touched.lastName && errors.lastName}
          />
          <TextField
            fullWidth
            autoComplete='username'
            label='Имя'
            {...getFieldProps('firstName')}
            error={Boolean(touched.firstName && errors.firstName)}
            helperText={touched.firstName && errors.firstName}
          />
          <TextField
            fullWidth
            autoComplete='username'
            label='Отчество'
            {...getFieldProps('middleName')}
            error={Boolean(touched.middleName && errors.middleName)}
            helperText={touched.middleName && errors.middleName}
          />
          <PhoneField
            fullWidth
            autoComplete='username'
            label='Номер телефона'
            {...getFieldProps('phone')}
            error={Boolean(touched.phone && errors.phone)}
            helperText={touched.phone && errors.phone}
          />
          <TextField
            fullWidth
            autoComplete='username'
            type='email'
            label='Email'
            {...getFieldProps('email')}
            error={Boolean(touched.email && errors.email)}
            helperText={touched.email && errors.email}
          />
          <TextField
            fullWidth
            label='Пароль'
            type='password'
            {...getFieldProps('password')}
            error={Boolean(touched.password && errors.password)}
            helperText={touched.password && errors.password}
          />
          <TextField
            fullWidth
            label='Повторите пароль'
            type='password'
            {...getFieldProps('passwordConfirmation')}
            error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
            helperText={touched.passwordConfirmation && errors.passwordConfirmation}
          />
          <FormControlLabel
            sx={{ alignItems: 'flex-start' }}
            control={<Checkbox onChange={() => setChecked((prev) => !prev)} />}
            label={
              <Typography variant='body2' color='text.secondary'>
                Ознакомлен и согласен с условиями
                <Link href='https://almaty-kansonar.kz/privacy-policy'> Договора офферты</Link> и с{' '}
                <Link href='https://almaty-kansonar.kz/refund-rules'>
                  регламентом по возврату денежных средств
                </Link>
              </Typography>
            }
          />
        </Stack>
        <LoadingButton
          fullWidth
          disabled={!checked}
          size='large'
          type='submit'
          variant='contained'
          loading={isSubmitting}
          sx={{ mb: 2 }}
        >
          Зарегестрироваться
        </LoadingButton>
      </Form>
    </FormikProvider>
  )
}
