import { Box, Card, Stack, styled, Typography } from '@mui/material'
import React from 'react'
import { Outlet } from 'react-router-dom'

interface IProps {
  children?: React.ReactNode
}

export function AuthLayout({ children }: IProps) {
  return (
    <Stack direction='row'>
      <SectionStyle>
        <Box mb={2}>
          <img src='/logo192.png' width={120} alt='login' />
        </Box>
        <Typography variant='h3'>«КАНСОНАР»</Typography>
        <Typography variant='h5' textAlign='center'>
          Платформа тестирования на знание Охотминимума
        </Typography>
      </SectionStyle>

      {!!children ? children : <Outlet />}
    </Stack>
  )
}

const SectionStyle = styled(Card)(({ theme }) => ({
  width: '100%',
  maxWidth: 464,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  margin: theme.spacing(2, 0, 2, 2),
  padding: theme.spacing(2),
}))
