import { styled, Box } from '@mui/material'
import ReactQuill from 'react-quill'
import 'react-quill/dist/quill.snow.css'

import EditorToolbar, { formats } from './EditorToolbar'

const RootStyle = styled(Box)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  border: `solid 1px ${theme.palette.grey[500_32]}`,
  '& .ql-container.ql-snow': {
    borderColor: 'transparent',
    ...theme.typography.body1,
    fontFamily: theme.typography.fontFamily,
  },
  '& .ql-editor': {
    minHeight: 200,
    '&.ql-blank::before': {
      fontStyle: 'normal',
      color: theme.palette.text.disabled,
    },
    '& pre.ql-syntax': {
      ...theme.typography.body2,
      padding: theme.spacing(2),
      borderRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.grey[900],
    },
  },
}))

interface IProps {
  value: string
  onChange: (e: string) => void
}

export function Editor({ value, onChange }: IProps) {
  const modules = {
    toolbar: {
      container: `#editor-toolbar`,
    },
  }

  return (
    <RootStyle>
      <EditorToolbar id='editor-toolbar' isSimple />
      <ReactQuill
        theme='snow'
        value={value}
        onChange={(val) => onChange(val)}
        modules={modules}
        formats={formats}
      />
    </RootStyle>
  )
}
