import { useNavigate } from 'react-router-dom'
import { Stack, TextField, Divider, Typography, Card, Box, CardContent } from '@mui/material'
import { LoadingButton } from '@mui/lab'
import * as Yup from 'yup'
import { useSnackbar } from 'notistack'
import {
  IUpdateUserDataInput,
  IUser,
  refetchMeQuery,
  useUpdateUserDataMutation,
} from '@kansonar-exam-front/graphql'
import { PhoneField, useIsMountedRef } from '@kansonar-exam-front/ui-kit'
import { useTranslation } from 'react-i18next'
import { Form, FormikProvider, useFormik } from 'formik'

import { useStores } from '../../stores/hooks'

interface IFormValues extends IUpdateUserDataInput {
  passwordConfirmation: string
  afterSubmit?: string
}

export function UpdateUserForm() {
  const { enqueueSnackbar } = useSnackbar()
  const { t } = useTranslation()
  const navigate = useNavigate()
  const isMountedRef = useIsMountedRef()
  const { authStore } = useStores()
  const { user } = authStore

  const [updateUser] = useUpdateUserDataMutation()

  const LoginSchema = Yup.object().shape({
    phone: Yup.string().required(),
    password: Yup.string(),
    passwordConfirmation: Yup.string().oneOf(
      [Yup.ref('password'), null],
      'Пароли должны совпадать'
    ),
  })

  const formik = useFormik<IFormValues>({
    initialValues: {
      phone: user?.phone || '',
      password: '',
      passwordConfirmation: '',
    },
    validationSchema: LoginSchema,
    onSubmit: async (values, { setErrors, setSubmitting }) => {
      try {
        const { data } = await updateUser({
          variables: {
            input: {
              phone: values.phone,
              password: values.password,
            },
          },
          refetchQueries: [refetchMeQuery()],
        })

        if (data?.updateUserData) {
          authStore.setUser(data.updateUserData as IUser)
          enqueueSnackbar('Данные успешно сохранены!', { variant: 'success' })
          navigate('/profile/details', { replace: true })
        }
      } catch (error) {
        console.error(error)
        setErrors({ afterSubmit: error.message })
      } finally {
        if (isMountedRef.current) {
          setSubmitting(false)
        }
      }
    },
  })

  const { errors, touched, isSubmitting, handleSubmit, getFieldProps } = formik

  if (!user) {
    return null
  }

  return (
    <FormikProvider value={formik}>
      <Form autoComplete='off' noValidate onSubmit={handleSubmit}>
        <Card sx={{ mb: 3 }}>
          <CardContent>
            <Stack spacing={3}>
              <Box>
                <Typography variant='subtitle1'>{user.fullName}</Typography>
                <Typography variant='body2' color='text.secondary'>
                  {user.email}
                </Typography>
              </Box>

              <PhoneField
                fullWidth
                autoComplete='username'
                label='Номер телефона'
                {...getFieldProps('phone')}
                error={Boolean(touched.phone && errors.phone)}
                helperText={touched.phone && errors.phone}
              />
              <Divider>
                <Typography variant='body2' color='text.secondary'>
                  Новый пароль
                </Typography>
              </Divider>
              <TextField
                fullWidth
                label='Пароль'
                type='password'
                {...getFieldProps('password')}
                error={Boolean(touched.password && errors.password)}
                helperText={touched.password && errors.password}
              />
              <TextField
                fullWidth
                label='Повторите пароль'
                type='password'
                {...getFieldProps('passwordConfirmation')}
                error={Boolean(touched.passwordConfirmation && errors.passwordConfirmation)}
                helperText={touched.passwordConfirmation && errors.passwordConfirmation}
              />
            </Stack>
          </CardContent>
        </Card>
        <Box textAlign='center'>
          <LoadingButton
            size='large'
            fullWidth
            type='submit'
            variant='contained'
            loading={isSubmitting}
          >
            {t('common.save')}
          </LoadingButton>
        </Box>
      </Form>
    </FormikProvider>
  )
}
